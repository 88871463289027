.post-item {
  display: block;
  position: relative;
  line-height: 24px;
  padding-bottom: 5px;
  margin: 5px 0;
}

.post-nav {
  padding-top: 100px;
  text-align: center;
}